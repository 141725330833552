.Logo-Image {
  height: 50px;
}

.Table-Row {
  padding: 5px;
  padding-left: 10px;
  margin: 0px;
  height: 50px !important;
}

.Table-Header {
  padding-top: 10px;
  padding-bottom: 10px;
}
