.magenta {
    background-color: rgba(216, 56, 100, .6);
}

.red {
    background-color: rgba(227, 82, 63, .6);
}

.green {
    background-color: rgba(151, 193, 93, .6); 
}

.orange {
    background-color: rgba(242, 156, 55, .6);
}

.turquoise {
    background-color: rgba(83, 185, 208, .6);
}

.blueGreen {
    background-color: rgba(65, 149, 136, .6);
}

.blue {
    background-color: rgba(45, 121, 196, .6);
}

.purple {
    background-color: rgba(146,51,171, .6);
}

.darkBlue {
    background-color: rgba(28, 78, 128, .6);
}

.yellow {
    background-color: rgba(244, 187, 97, .6);
}

.magenta-bold {
    background-color: rgba(216, 56, 100, .73);
}

.red-bold {
    background-color: rgba(227, 82, 63, .73);
}

.green-bold {
    background-color: rgba(151, 193, 93, .73);
}

.orange-bold {
    background-color: rgba(242, 156, 55, .73);
}

.turquoise-bold {
    background-color: rgba(83, 185, 208, .73);
}

.blueGreen-bold {
    background-color: rgba(65, 149, 136, .73);
}

.blue-bold {
    background-color: rgba(45, 121, 196, .73);
}

.purple-bold {
    background-color: rgba(146,51,171, .73);
}

.darkBlue-bold {
    background-color: rgba(28, 78, 128, .73);
}

.yellow-bold {
    background-color: rgba(244, 187, 97, .73);
}