/* h1 {
  font-size: 50px;
}

h2 {
  font-size: 12px;
}

h3 {
  font-size: 100px;
  color: red;
}

h4 {
  font-size: 30px;
  color: blue;
}

body {
  font-size: 30px;
  color: blue;
} */

html, body, #root {
  height: 100vh;
  margin: 0;
  padding: 0;
}


.App {
  background-color: white;
  justify-content: center;
  min-height: 100%;
}

.App-logo {
  height: 10%;
  width: 20%;
  justify-content: left;

}

.SignUpInfo{
  padding-left: 20px;
}

.ErrorMessage{
  color: red;
}

.LeftContent{
float: left;
padding-left: 150px;
}

.RightContent{
float: right;
padding-right: 150px;
}

.MainButton{
  padding-left: 600px;
  padding-right: 1000px;
  padding-top: 250px;
  padding-bottom: 0px;
  justify-content: center;
}

.padding-example {
  padding-top: 20px;
}

.padding-top {
  padding-top: 10px;
}

.button {
  border-top: 1px solid #336d99;
  background: #336d99;
  background: -webkit-gradient(linear, left top, left bottom, from(#336d99), to(#336d99));
  background: -webkit-linear-gradient(top, #336d99, #336d99);
  background: -moz-linear-gradient(top, #336d99, #336d99);
  background: -ms-linear-gradient(top, #336d99, #336d99);
  background: -o-linear-gradient(top, #336d99, #336d99);
  padding: 10px 20px;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  border-radius: 14px;
  -webkit-box-shadow: rgba(0,0,0,1) 0 1px 0;
  -moz-box-shadow: rgba(0,0,0,1) 0 1px 0;
  box-shadow: rgba(0,0,0,1) 0 1px 0;
  text-shadow: rgba(0,0,0,.4) 0 1px 0;
  color: white;
  font-size: 13px;
  font-family: 'Lucida Grande', Helvetica, Arial, Sans-Serif;
  text-decoration: none;
  vertical-align: middle;
  }
.button:hover {
  border-top-color: #28597a;
  background: #28597a;
  color: #ccc;
  }
.button:active {
  border-top-color: #1b435e;
  background: #1b435e;
  }

.plain_link {
  color: #336d99;
  text-decoration-line: none;
  font-size: 14px;
}


/* .iframe-container{
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}

.iframe-container iframe{
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
} */

.plain_link:visited {
  color: #336d99;
  text-decoration-line: none;
}

.plain_link:hover {
  color: #007bff;
  text-decoration-line: none;
}

.grey_link {
  color: #242424;
  text-decoration-line: none;
}


.grey_link:visited {
  color: #242424;
  text-decoration-line: none;
}

.grey_link:hover {
  color: #242424;
  text-decoration-line: none;
}

.error {
  color: #ff5953
}

.centered {
  text-align: center;
}

.centered-white {
  text-align: center;
  color: white;
}

.rightAligned {
  text-align: end;
  font-size: 1em;
  color: #336d99;
}

.FloatingActionButton {
  margin: 0;
  top: 'auto';
  right: 20;
  bottom: 20;
  left: 'auto';
  position: 'fixed';
};

.PaddingHorizontal {
  padding-left: 20px;
  padding-right: 20px;
}

.screenshots {
  max-height:500px;
  max-width:500px;
  height:auto;
  width:auto;
}

.Padding-Left {
  padding-left: 10px;
}

.Padding-Right {
  padding-right: 10px;
}

.Padding-Left-More {
  padding-left: 20px;
}

tr td {
  height: auto !important;
}

.Highlighted-Day {
  color: orange;
}

.Blue-Background {
  background-color: #336d99;
}

.Red-Background {
  background-color: #ff5953;
}

.Yellow-Background {
  background-color: yellow;
}

.Green-Background {
  background-color: aquamarine;
}

.Small-Text {
  font-size: 3;
}

.MadPadding {
  padding: 10px;
}

.RealMadPadding {
  padding: 20px; 
}

body {
  margin: 0;
}

#left {
  position: fixed;
  width: 30%;
  height: 100%;
  overflow: auto;
}

#right {
  position: fixed;
  border-style: none none none solid;
  border-width: thin;
  border-color: #212121;
  width: 70%;
  height: 100%;
  right: 0;
}

#right-bottom {
 
  position: absolute;
  width: 69%;
  right: 0; 
  bottom: 0;
}

.Padding-Bottom {
  margin-bottom: 100000000px;
}

.MessageCard {
  padding-top: 5px;
}

.TextMessageCard {
  background-color: #007bff;
  color: white;
  width: 50%;
}

.appBar {
  top: 'auto';
  bottom: 0;
}

.messageBarContainer {
  position: relative;
  height: 100%;
}

.messageBar {
  position: absolute; 
  width: 100%;
  bottom: 0;
  right: 0;
}

.messageTextField {
  width: 90%, 
  
}

.padding-vertical {
  padding-top: 5px;
  padding-bottom: 5px;
}

.fill-paper {
  width: 100%; 
}

.read-receipt-text {
  color: '#FFFFFF';
  font-size: 16;
  font-weight: 300;
  margin: 0;
}

.flutter-column {
  display: flex;
  height: 100%;
  flex-flow: column;
  max-height: 100%;
  align-items: stretch;
}

.flutter-flex-element {
  flex: 0, 0, 33px;
}

.flutter-flex-expanded {
  flex: 1, 1, 200px;
  overflow: auto;
  max-height: calc(100% - 130px);
  min-height: calc(100% - 130px);
  max-width: calc(100% - 10px);
  min-width: calc(100% - 10px);
  padding-left: 5px;
}

.flutter-flex-expanded-less {
  flex: 1, 1, 200px;
  overflow: auto;
  max-height: calc(100% - 250px);
  min-height: calc(100% - 250px); 
}

.flutter-flex-footer {
  flex: 0, 0, 28px;
  order:1;
  padding: none;
  margin: none;
  max-width: 99%;
  
}

.ProgressContainer {
  display: block;
  width: 135px;
}
.Block {
  flex: 1 1 auto;
}

.box .row {
  border: 1px dotted grey;
}

.page-header {
  flex: 0 1 auto;
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.page-content {
  flex: 1 1 100%;
}

.box .row.footer {
  flex: 0 1 40px;
}

.no-padding {
  padding: 0px;
  margin: 0px;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-o-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}
.progress-bar {
  float: left;
  width: 0%;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  text-align: center;
  background-color: #337ab7;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}
.progress-striped .progress-bar,
.progress-bar-striped {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  -webkit-background-size: 40px 40px;
  background-size: 40px 40px;
}
.progress.active .progress-bar,
.progress-bar.active {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}
.progress-bar-success {
  background-color: #a2e6ad;
}
.progress-striped .progress-bar-success {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-info {
  background-color: #5bc0de;
}
.progress-striped .progress-bar-info {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-warning {
  background-color: #ffff7f;
}
.progress-striped .progress-bar-warning {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-danger {
  background-color: #d9534f;
}
.progress-striped .progress-bar-danger {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.hidden {
  display: none;
}