.Title-Text {
  font-size: 24px;
}

.Header-Text {
  font-size: 14px;
}

.Standard-Text {
  font-size: 12px;
}

.Selected {
  color: #ffa814;
}

.Header-Text-Row {
  font-size: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
}



.Faded {
  color: #757575;
}

.Bold {
  font-weight: bold;
}

.Blue {
  color: #007bff;
}

.Green {
  color: #00da21;
}

.Red {
  color: #de2700;
}

.Orange {
  color: #ffa814;
}

.Blue-Background {
  background-color: rgb(0, 123, 255, .5);
  border-radius: 10px;
  display: inline-block;
  margin-top: 2px;
  margin-bottom: 2px;
  padding-right: 5px;
  padding-left: 5px;
}

.Green-Background {
  background-color: rgb(77, 215, 101, .5);;
  border-radius: 10px;
  display: inline-block;
  margin-top: 2px;
  margin-bottom: 2px;
  padding-right: 5px;
  padding-left: 5px;
}

.Red-Background {
  background-color: rgb(255, 58, 49, .5);
  border-radius: 10px;
  display: inline-block;
  margin-top: 2px;
  margin-bottom: 2px;
  padding-right: 5px;
  padding-left: 5px;
}

.Orange-Background {
  background-color: rgb(255, 255, 0, .5);
  border-radius: 10px;
  display: inline-block;
  margin-top: 2px;
  margin-bottom: 2px;
  padding-right: 5px;
  padding-left: 5px;
}

.Standard-Background {
  background-color: white;
  border-radius: 10px;
  display: inline-block;
  margin-top: 2px;
  margin-bottom: 2px;
  padding-right: 5px;
  padding-left: 5px;
}

.Grey-Background {
  background-color: rgb(180, 180, 180, .5);
  border-radius: 10px;
  display: inline-block;
  margin-top: 2px;
  margin-bottom: 2px;
  padding-right: 5px;
  padding-left: 5px;
}

.Purple-Background {
  background-color: rgb(213, 202, 247);
  border-radius: 10px;
  display: inline-block;
  margin-top: 2px;
  margin-bottom: 2px;
  padding-right: 5px;
  padding-left: 5px;
}

.Dark-Red-Background {
  background-color: rgb(216, 16, 52, .75);
  border-radius: 10px;
  display: inline-block;
  margin-top: 2px;
  margin-bottom: 2px;
  padding-right: 5px;
  padding-left: 5px;
}
